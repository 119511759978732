// importPages 後面自動補逗號，避免多人同時修改時容易出現檔案衝突
/* eslint comma-dangle: ["error", "always"] */

// 頁面組件
import Login from '@/views/Login/index.vue'
import Exception404 from '@/views/404.vue'
import Working from '@/views/Working.vue'

/**
 * 兩種引入方法
 * 1. 在上面用 import 會直接把內容嵌入在主核心
 * 2. 用 () => import 會用異步載入減少檔案大小
 */
const importPages = {
  Login,
  Working,
  // exception
  // Exception403: () => import('@/views/exception/403'),
  Exception404,
  // Exception500: () => import('@/views/exception/500'),

  // 你需要动态引入的页面组件
  About: () => import('@/views/About.vue'),
  // Home: () => import('@/views/Home.vue'),
  Home: () => import('@/views/MyProfile/index.vue'),
  // 我的檔案 / 取得登入者訊息
  UserInfo: () => import('@/views/MyProfile/index.vue'),
  // 個人中心 / IP 白名單設定(個人)
  IpAccessible: () => import('@/views/IpAccessible.vue'),
  // 帳號管理 / 公司
  Company: () => import('@/views/Accounts/Company/index.vue'),
  // 帳號管理 / 財務
  Finance: () => import('@/views/Accounts/Finance/index.vue'),
  // 帳號管理 / 高級總代理
  MasterAgent: () => import('@/views/Accounts/MasterAgent/index.vue'),
  // 帳號管理 / 總代理(第1級代理)
  FirstAgent: () => import('@/views/Accounts/FirstAgent/index.vue'),
  // 帳號管理 / 代理
  Agent: () => import('@/views/Accounts/Agent/index.vue'),
  // 帳號管理 / 玩家
  Player: () => import('@/views/Accounts/Player/index.vue'),
  // 帳號管理 / 子帳號
  UserSub: () => import('@/views/UserSub/index.vue'),
  // 帳號管理 / 黑名單管理
  Blacklist: () => import('@/views/Blacklist/index.vue'),
  // 帳號管理 / 導出紀錄
  DataExportHistory: () => import('@/views/DataExportHistory/index.vue'),
  // 帳號管理 / 操作紀錄
  ModifyLogs: () => import('@/views/ModifyLogs/index.vue'),
  // 帳號管理 / 登入紀錄
  LoginRecord: () => import('@/views/LoginRecord/index.vue'),
  // 帳號管理 / 收件箱紀錄
  InboxRecord: () => import('@/views/InboxRecord/index.vue'),
  // 帳號管理 / 建议反馈
  Feedback: () => import('@/views/Feedback/index.vue'),
  // 帳號管理 / 洗碼量紀錄
  CodeWashingRecord: () => import('@/views/CodeWashingRecord/index.vue'),
  // 帳號管理 / 流水玩家
  UserTurnoverBet: () => import('@/views/UserTurnoverBet/index.vue'),
  // 報表 / 每日輸贏
  WinLost: () => import('@/views/Report/DailyWinLose/index.vue'),
  // 報表 / 產品輸贏
  WinLostByProduct: () => import('@/views/Report/ProductWinLose/index.vue'),
  // 報表 / 交易歷史
  TransactionHistory: () => import('@/views/Report/TransactionHistory/index.vue'),
  // 報表 / 未結束交易
  Outstanding: () => import('@/views/Report/UnfinishedTransaction/index.vue'),
  // 報表 / 充值報表
  DepositReport: () => import('@/views/Report/DepositReport/index.vue'),
  // 轉點 / 帳號轉點
  Transfer: () => import('@/views/TransferPoints/Transfer/index.vue'),
  // 轉點 / 上下分紀錄
  TransferRecord: () => import('@/views/TransferPoints/TransferRecord/index.vue'),
  // 轉點 / 上下分審核
  TransferRecordSetStatus: () => import('@/views/TransferPoints/SetStatus/index.vue'),
  // 網站內容管理 / 站台設置
  SiteInfo: () => import('@/views/SiteInfo/index.vue'),
  // 網站內容管理 / 公告管理
  SiteNews: () => import('@/views/SiteNews/index.vue'),
  // 網站內容管理 / 輪播圖
  SiteSlideshow: () => import('@/views/SiteSlideshow/index.vue'),
  // 網站內容管理 / 浮動圖
  SiteFloat: () => import('@/views/SiteFloat/index.vue'),
  // 遊戲管理 / 平台設置
  GameInfo: () => import('@/views/GameInfo/index.vue'),
  // 遊戲管理 / 平台維護管理
  GameMaintenance: () => import('@/views/GameMaintenance/index.vue'),
  // 遊戲管理 / 遊戲管理
  GameAPI: () => import('@/views/GameAPI/index.vue'),
  // 遊戲管理 / 分類管理
  GameTabManagement: () => import('@/views/GameTabManagement/index.vue'),
  // 遊戲管理 / 遊戲排序
  GameFrontend: () => import('@/views/EditSort/index.vue'),
  // 遊戲管理 / 排行榜
  GameLeaderboard: () => import('@/views/GameLeaderboard/index.vue'),
  // 遊戲管理 / 平台數據下載
  GameApiBet: () => import('@/views/GameApiBet/index.vue'),
  // 遊戲管理 / 遊戲紀錄API
  GameApiLog: () => import('@/views/GameApiLog/index.vue'),
  // 遊戲管理 / Daemon 管理
  DaemonManager: () => import('@/views/DaemonManager/index.vue'),
  // 遊戲管理 / 遊戲數據重整
  BetTableDefragment: () => import('@/views/BetTableDefragment/index.vue'),
  // 行銷管理 / 返水設置
  RebateSettings: () => import('@/views/MarketingManagement/RebateSettings/index.vue'),
  // 行銷管理 / 返水派發
  RebatePayout: () => import('@/views/MarketingManagement/RebatePayout/index.vue'),
  // 優惠管理 / 用戶首充獎勵
  FirstDepositPromotion: () => import('@/views/Promotion/FirstDepositPromotion/index.vue'),
  // 優惠管理 / 用戶充值獎勵
  DepositPromotion: () => import('@/views/Promotion/DepositPromotion/index.vue'),
  // 優惠管理 / 老带新奖励
  DevelopPromotion: () => import('@/views/Promotion/DevelopPromotion/index.vue'),
  // 優惠管理 / 優惠報表
  PromotionReport: () => import('@/views/Promotion/PromotionReport/index.vue'),
  // 優惠管理 / 老带新優惠報表
  DevelopPromotionReport: () => import('@/views/Promotion/DevelopPromotionReport/index.vue'),
  // 金流管理 / 银行入款
  BankTransfer: () => import('@/views/CashManager/BankTransfer/index.vue'),
  // 金流管理 / 线上支付入款
  ThirdPartyPayment: () => import('@/views/CashManager/ThirdPartyPayment/index.vue'),
  // 金流管理 / 出款
  WithdrawalTransfer: () => import('@/views/CashManager/WithdrawalTransfer/index.vue'),
  // 金流设置 / 层级管理
  LevelConf: () => import('@/views/CashSettings/LevelConf/index.vue'),
  // 金流设置 / 入款銀行帳號設置
  FunInAccount: () => import('@/views/CashSettings/FunInAccount/index.vue'),
  // 金流设置 / 存款支付平台
  ThirdPartyDeposit: () => import('@/views/CashSettings/ThirdPartyDeposit/index.vue'),
  // 金流设置 / 提款支付平台
  ThirdPartyWithdrawal: () => import('@/views/CashSettings/ThirdPartyWithdrawal/index.vue'),
  // 金流设置 / 支付平台设置
  ThirdSetting: () => import('@/views/CashSettings/ThirdSetting/index.vue'),
  // 金流设置 / 支付渠道设置
  ThirdChannel: () => import('@/views/CashSettings/ThirdChannel/index.vue'),
  // 金流设置 / 银行设置
  Bank: () => import('@/views/Bank/index.vue'),
  // 金流设置 / API请求纪录
  ThirdApiLog: () => import('@/views/CashSettings/ThirdApiLog/index.vue'),
  // 金流设置 / 自动代付纪录
  AutoPaymentLog: () => import('@/views/CashSettings/AutoPaymentLog/index.vue'),
  // 系統管理 / 系統設置
  SystemSettings: () => import('@/views/SystemSettings/index.vue'),
  // 系統管理 / IP存取設定 - 黑白名單管理 (全系統)
  IpAccessibleSystem: () => import('@/views/IpAccessibleSystem/index.vue'),
  // 站台狀態 / 總餘額
  BalanceStatistics: () => import('@/views/BalanceStatistics/index.vue'),
  // 行銷管理 / 活動管理
  // ActivityManagement: () => import('@/views/MarketingManagement/ActivityManagement/index.vue'),
  // 行銷管理 / 活動派发
  // ActivityPayout: () => import('@/views/MarketingManagement/ActivityPayout/index.vue'),
  // 優惠管理
  // Promotion: () => import('@/views/Promotion/PromotionSetting/index.vue'),
  // 帳號管理 / IP稽核
  // IPAudit: () => import('@/views/IPAudit/index.vue'),
  // 線上使用者 - 未使用
  OnlineUser: () => import('@/views/OnlineUser/index.vue'),
  // 支付方式设置 - 未使用
  // ThirdMethod: () => import('@/views/CashSettings/ThirdMethod/index.vue'),
  // Icon 管理
  IconManager: () => import('@/views/IconManager/index.vue'),
}

export default importPages
