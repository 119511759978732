import request from '@/utils/request'
import ResponseFormat from './response-format'

const url = {
  view: '/ThirdPartyPayment/view',
  getItem: '/ThirdPartyPayment/getItem',
  manual: '/ThirdPartyPayment/manual',
  reject: '/ThirdPartyPayment/reject',
  viewExport: '/ThirdPartyPayment/export',
  search: '/ThirdPartyPayment/search',
  getStatusList: '/ThirdPartyPayment/getStatusList',
  getNotifications: '/ThirdPartyPayment/getNotifications'
}

/**
 * 列表
 */
export interface viewReq {
  account: number,
  order_id:number,
  status: number,
  start: number,
  end: number,
  third_id: number,
  channel_id: number,
  method_id: number,
  exp_flag: number // 不传单纯查询，汇出传1
}
export function view (params: viewReq) {
  return request({
    url: url.view,
    method: 'get',
    params
  }) as unknown as Promise<ResponseFormat<void>>
}

// 取选
export function getItem (parameter: {
  status: number,
  show_bank: number
}) {
  return request({
    url: url.getItem,
    method: 'get',
    params: parameter
  }) as unknown as Promise<ResponseFormat<[]>>
}

/**
 * 下載
 */
export function download (params: viewReq) {
  return request({
    url: url.view,
    method: 'get',
    params
    // responseType: 'blob',
    // headers: { 'Content-Type': 'application/json; application/octet-stream' },
    // timeout: Infinity // 请求时间若資料多、會比較久，先設到無極限
  }) as unknown as Promise<ResponseFormat<viewReq[]>>
}

/**
 * 手动
 */
export function manual (data: {
  id: number
}) {
  return request({
    url: url.manual,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<[]>>
}

/**
 * 拒绝
 */
export function reject (data: {
  id: number
}) {
  return request({
    url: url.reject,
    method: 'post',
    data
  }) as unknown as Promise<ResponseFormat<[]>>
}

/**
 * 导出
 */
export interface viewExportReq {
  player_id: number
}
export function viewExport (params: viewExportReq) {
  return request({
    url: url.viewExport,
    method: 'get',
    params
  }) as unknown as Promise<ResponseFormat<void>>
}
/**
 * 查询
 */
export interface searchReq {
  id: number
}
export function search (params: searchReq) {
  return request({
    url: url.search,
    method: 'post',
    params
  }) as unknown as Promise<ResponseFormat<void>>
}
// /**
// * 第三方支付入款通知
// */
// export interface noticeReq {
//   id: number
// }
// export function notice (params: noticeReq) {
//   return request({
//     url: url.notice,
//     method: 'get',
//     params
//   }) as unknown as Promise<ResponseFormat<void>>
// }
/**
 * 狀態列表
 */
export function getStatusList () {
  return request({
    url: url.getStatusList,
    method: 'get'
  }) as unknown as Promise<ResponseFormat<[]>>
}

/**
 * 取得待處理數量
 */
export function getNotifications () {
  return request({
    url: url.getNotifications
  }) as Promise<ResponseFormat<Number>>
}
